import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {COMPANY_NAME} from "../../utils/constants";

type NavigationProps = {
    name: string;
    path: string;
    start: number;
    enabled: boolean;
}

const navigations: NavigationProps[] = [
    {
        name: 'HOME',
        path: '/',
        start: 4,
        enabled: false,
    },
    {
        name: 'TERMS OF SERVICE',
        path: '/terms',
        start: 6,
        enabled: true,
    },
    {
        name: 'CONTACT US',
        path: '/contact-us',
        start: 8,
        enabled: false,
    },
];

function Navigation() {
    const location = useLocation();

    return (
        <div className="">
            <div className="relative w-full px-8 text-gray-700 bg-white body-font shadow-lg ">
                <div
                    className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl ">
                    <Link to="/terms" className="relative z-10 flex items-center w-auto text-2xl font-extrabold leading-none text-black select-none">{COMPANY_NAME}</Link>

                    <nav className="top-0 left-0 z-0 flex items-center justify-center w-full h-full py-5 -ml-0 space-x-9 text-base text-lg absolute">
                        {
                            navigations.map((nav, index) =>
                                <Link key={index} to={nav.enabled ? nav.path : '#'} className={`relative font-medium leading-6 text-gray-600 transition duration-150 ease-out hover:text-primary ${location.pathname === nav.path && 'text-primary'}`} >
                                    <span className="block">{nav.name}</span>
                                </Link>
                            )
                        }

                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Navigation;

export const COMPANY_NAME: string = 'Web Design Creations';
export const PAYMENT_DAYS_BEFORE_DUE_DATE: number = 5;
export const PAYMENT_GRACE_PERIOD: number = 7;
export const PAYMENT_INCREASE_NOTIFICATION_DATE: number = 20;
export const PAYMENT_REACTIVATION_FEE: string = "R70.00";

export const PAYMENT_YEARLY_DAYS_BEFORE_TERMINATION_DATE: number = 30;

export const REFUSE_OF_ACCESS_ACCEPT_DAYS: number = 30;

export const EMAIL_ADMIN: string = "admin@webdesigncreations.co.za";

export const SERVICES_PROVIDED: string[] = [
    'Domain Name Hosting Management',
    'Email Hosting Management',
    'Website Hosting Management',
    'Website Management'
];
import React from 'react';
import {COMPANY_NAME} from "../../utils/constants";

function Footer() {
    return (
        <div className="pt-1">
            <div className="relative w-full px-8 text-gray-700 bg-white body-font shadow-lg ">
                <div
                    className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl ">
                    <div className="relative z-10 flex items-center w-auto text-2xl font-extrabold leading-none text-black select-none">{COMPANY_NAME}</div>

                </div>
            </div>
        </div>
    );
}

export default Footer;

import React from 'react';

function Home() {
    return (
        <div >
            Home
        </div>
    );
}

export default Home;

import React, {FunctionComponent} from 'react';
import {
    COMPANY_NAME,
    EMAIL_ADMIN,
    PAYMENT_DAYS_BEFORE_DUE_DATE,
    PAYMENT_GRACE_PERIOD,
    PAYMENT_INCREASE_NOTIFICATION_DATE,
    PAYMENT_REACTIVATION_FEE,
    PAYMENT_YEARLY_DAYS_BEFORE_TERMINATION_DATE,
    REFUSE_OF_ACCESS_ACCEPT_DAYS,
    SERVICES_PROVIDED
} from "../../utils/constants";

type SectionProps = {
    title: string,
    body: string[],
    depth: number,
    children?: SectionProps[]
}

const infos: Array<SectionProps> = [
    {
        title: 'Monthly Payments',
        body: [
            'Full payment is required before any hosting service is rendered, which includes renewals. ' +
            'All payment invoices will be sent out via email. ' +
            'Payment is due (Should be received on) the first of the month, for that month. ' +
            `All customers will receive invoices ${PAYMENT_DAYS_BEFORE_DUE_DATE} days before the due date. ` +
            'Billing will continue until you cancel your account via a Letter of Cancellation. ' +
            'We accept the following forms of payment: Electronic Funds Transfer (EFT). ',
            `Initial monthly payment amount is dependent on the services chosen, as well as the amount agreed upon by ${COMPANY_NAME} and you. `,
            `The payment amount is subject to change suddenly due to changes in the market. ` +
            `Changes in the payment amount will be communicated ${PAYMENT_INCREASE_NOTIFICATION_DATE} days before the bill is set to increase.`
        ],
        depth: 0,
    },
    {
        title: 'Yearly Payments',
        body: [
            `Website hosting payments can be paid for one year, up-front, to enjoy a discounted rate. ` +
            `If the one year, up-front, option is chosen, full payment is required before the website will be made live. ` +
            `${COMPANY_NAME} will send an invoice via email, for the yearly amount that is due. ` +
            `If the yearly option is to be renewed, the new invoice for the next year will be sent ${PAYMENT_YEARLY_DAYS_BEFORE_TERMINATION_DATE} days before the termination date of the current one year plan`,
            `The cost of the one year up front is subject to change. ` +
            `Failure to make payment within set out amount of days, will invoke 'Terms and Conditions - Late Payment'`
        ],
        depth: 0,
    },
    {
        title: 'Late Payments',
        body: [
            'It is your responsibility to ensure that your payment information is up to date, and that all invoices are paid on time. ' +
            `You agree that until and unless you notify ${COMPANY_NAME} of your desire to cancel any or all services received (by the proper means listed in the appropriate section of the Terms & Conditions), those services will be billed on a recurring basis. ` +
            `${COMPANY_NAME} reserves the right to allocate payments to the oldest outstanding invoice on your account. `,

            `${COMPANY_NAME} provides a ${PAYMENT_GRACE_PERIOD} day grace period from the time the invoice is due and when it must be paid. ` +
            `Any invoice that is overdue for ${PAYMENT_GRACE_PERIOD} days will result in account and service suspension until account balance has been paid in full. ` +
            `Access to the account will not be restored until all outstanding payments have been received. ` +
            `There will be a reactivation fee of ${PAYMENT_REACTIVATION_FEE}.`,

            `Non-payment of outstanding service fee invoices will result in termination of the hosting service. ` +
            `There is no guarantee that a backup will be available. `
        ],
        depth: 0,
    },
    {
        title: 'Account Cancellation',
        body: [
            `Cancellations must be done via email to ${EMAIL_ADMIN}, in the form of a Letter of Cancellation. ` +
            `You have the option of cancelling your account immediately or at the end of the billing period. ` +
            `If you choose to cancel the account immediately, then your account resources will be terminated on receipt of the cancellation. ` +
            `Cancellation of an account does not cancel any outstanding invoices. All outstanding invoices are still due. ` +
            `No refund will be given for the one year, up-front, option. `
        ],
        depth: 0,
    },
    {
        title: 'Account Resources',
        body: [
            `Your account resources is the services you chose when signing up with ${COMPANY_NAME}. ` +
            `These services could one or more of the following services: ${SERVICES_PROVIDED.join(', ')}. `
        ],
        depth: 0,
    },
    {
        title: 'Working Hours',
        body: [
            `${COMPANY_NAME} is operating within business hours, 08:00-16:00, Mon-Fri. ` +
            `As such, any updates to the website, mail boxes and domains, will only be done within these business hours. `
        ],
        depth: 0,
    },
    {
        title: 'Service Management',
        body: [
            `${COMPANY_NAME} uses reliable and trusted service providers to power your services. ` +
            `${COMPANY_NAME} reserves the right take make changes to these services, to ensure that you are secured and protected. `
        ],
        children: [
            {
                title: 'Domain Name Hosting Management',
                body: [
                    `${COMPANY_NAME} manages your domain name for you. ` +
                    `Any required changes to your domain name can be requested, by contacting your ${COMPANY_NAME} contact. `
                ],
                depth: 1,
            },
            {
                title: 'Email Hosting Management',
                body: [
                    `${COMPANY_NAME} manages your email boxes for you. ` +
                    `Any required changes to your email boxes can be requested, by contacting your ${COMPANY_NAME} contact. `
                ],
                depth: 1,
            },
            {
                title: 'Website Hosting Management',
                body: [
                    `${COMPANY_NAME} manages your website hosting for you. `
                ],
                depth: 1,
            },
            {
                title: 'Website Management',
                body: [
                    `${COMPANY_NAME} will perform all updates to your website. ` +
                    `This includes content updates, newsletter updates, routine maintenance and extra updates. ` +
                    `Content updates includes wording, images and general changes to the content that reside on your website. ` +
                    `${COMPANY_NAME} reserves the right to routinely changes aspects on the website, as this is part of our main goals in keeping your website fresh. ` +
                    `This changes will be communicated to you after being changed, and can be reverted upon request. `,
                    `Content and newsletter updates will only be done within business hours. ` +
                    `Large content updates can take more than one day to be updated. `
                ],
                depth: 1,
            },
        ],
        depth: 0,
    },
    {
        title: 'Intellectual Property',
        body: [
            `${COMPANY_NAME} is the owner of all content that appear or is contained on any website designed by ${COMPANY_NAME}, unless otherwise indicated. ` +
            `A copy of the website upon account cancellation will not be given to you. The website and content remain the property of ${COMPANY_NAME}, even after account termination. `
        ],
        depth: 0,
    },
    {
        title: 'Refusal of Service',
        body: [
            `${COMPANY_NAME} reserve the right to refuse service to anyone for any reason not prohibited by law. ` +
            `${COMPANY_NAME} reserve the right to terminate service to any customer for any reason not prohibited by law. ` +
            `Any deliberate attempt to cause damage to content/server providers for ${COMPANY_NAME} or any other internet servers will result in immediate account deactivation without prior notice. ` +
            `${COMPANY_NAME} reserve the right to make changes to this terms and conditions, where by you must accept this terms and conditions within ${REFUSE_OF_ACCESS_ACCEPT_DAYS} days. ` +
            `Failure to accept this terms and conditions within ${REFUSE_OF_ACCESS_ACCEPT_DAYS} days, will result in an immediate account termination.`
        ],
        depth: 0,
    }
];

const TermsSection: FunctionComponent<SectionProps> = ({title, body, depth, children}) => {
    return (
        <div
            className={`ml-${depth * 4} mr-10 mt-6 ${depth === 0 ? 'border-l-2' : ''} border-secondary border-opacity-10`}>
            <div className="ml-3">
                <div className="text-xl font-medium text-gray-700">{title}</div>
                {
                    body.map((bodySection, index) => (
                        <div key={index}>
                            <p className="text-gray-500 mt-1">{bodySection}</p>
                            <div className={'mt-5'}/>
                        </div>
                    ))
                }
                {
                    children &&
                    children.map((child, index) =>
                        <TermsSection
                            title={child.title}
                            body={child.body}
                            children={child.children}
                            depth={child.depth}
                            key={index}
                        />
                    )
                }
            </div>

        </div>
    );
}

function Terms() {

    return (
        <div className="p-5 ">
            <div className="grid grid-cols-12">
                <div className="col-start-4 col-span-6">
                    <div className="p-6 mx-auto bg-white rounded-xl shadow-md">
                        <h1 className="text-center text-3xl text-primary tracking-wide">Terms and Conditions</h1>
                        <h2 className="mt-5 text-primary tracking-wide">Last Updated: 05/05/2021</h2>
                        {
                            infos.map(info =>
                                <TermsSection
                                    title={info.title}
                                    body={info.body}
                                    children={info.children}
                                    depth={info.depth}
                                    key={info.title}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Terms;

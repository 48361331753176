import React from 'react';

function ContactUs() {
    return (
        <div>
            Contact Us
        </div>
    );
}

export default ContactUs;

import React from 'react';
import './App.css';
import Home from "./pages/home/home";
import Terms from "./pages/terms/Terms";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Navigation from "./components/navigation/Navigation";
import ContactUs from "./pages/contact/ContactUs";
import Footer from "./components/footer/Footer";

function App() {
    return (
        <div className="bg-main-background bg-cover bg-fixed bg-center bg-no-repeat min-h-screen ">
            <Router>
                <div>
                    <Navigation/>
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route path="/terms">
                            <Terms/>
                        </Route>
                        <Route path="/contact-us">
                            <ContactUs/>
                        </Route>
                    </Switch>
                </div>
            </Router>

            <div className="px-3">
                <div className="grid grid-cols-6 max-w-full">
                    <div className="col-start-6 col-span-1">
                        <div className=" text-center p-1 bg-white rounded shadow">
                            <span className="text-gray-700 ">Photo by </span>
                            <a className="underline text-primary font-semibold"
                               href="https://unsplash.com/@heytowner?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                            >JOHN TOWNER</a>
                            <span className="text-gray-700 "> on </span>
                            <a className="underline text-primary font-semibold"
                               href="https://unsplash.com/s/photos/wallpaper?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                            >Unsplash</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    );
}

export default App;
